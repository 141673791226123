import React from 'react'
export const icons = {
  all: (
    <svg
      className="all"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g data-name="all białe" transform="translate(-0.141)">
        <rect
          className="white"
          id="Rectangle_567"
          data-name="Rectangle 567"
          width="25"
          height="25"
          transform="translate(0.141)"
          fill="#fff"
        />
        <path
          className="red"
          id="Path_308"
          data-name="Path 308"
          d="M20.938,12.5a4.641,4.641,0,0,1-1.106,3.091A3.891,3.891,0,0,1,16.875,16.9c-3.231,0-4.647-4.063-4.706-4.241s-1.322-3.553-3.75-3.553A3.131,3.131,0,0,0,5.356,12.5a3.131,3.131,0,0,0,3.063,3.391A3.429,3.429,0,0,0,11.1,14.313a10.313,10.313,0,0,0,.531.938,4.186,4.186,0,0,1-3.216,1.641,3.909,3.909,0,0,1-2.972-1.312A4.653,4.653,0,0,1,4.341,12.5a4.644,4.644,0,0,1,1.1-3.088A3.909,3.909,0,0,1,8.413,8.1c3.181,0,4.641,4.062,4.706,4.237,0,.038,1.231,3.553,3.75,3.553A3.131,3.131,0,0,0,19.928,12.5a3.13,3.13,0,0,0-3.053-3.391,3.472,3.472,0,0,0-2.684,1.606c-.163-.312-.344-.625-.534-.937a4.167,4.167,0,0,1,3.219-1.672,3.891,3.891,0,0,1,2.966,1.313,4.631,4.631,0,0,1,1.1,3.081Z"
          fill="#e54448"
        />
      </g>
    </svg>
  ),
  news: (
    <svg
      className="news"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g
        id="aktualności_białe"
        data-name="aktualności białe"
        transform="translate(-0.141)"
      >
        <path
          className="white"
          id="Path_309"
          data-name="Path 309"
          d="M12.641,0a12.5,12.5,0,1,0,12.5,12.5A12.5,12.5,0,0,0,12.641,0Z"
          fill="#fff"
        />
        <g id="check">
          <path
            className="red"
            id="Path_310"
            data-name="Path 310"
            d="M9.878,15.866,6.253,12.241,5.047,13.45l4.831,4.831L20.234,7.928,19.025,6.719Z"
            fill="#e54448"
          />
        </g>
      </g>
    </svg>
  ),
  products: (
    <svg
      className="products"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="23.751"
      viewBox="0 0 25 23.751"
    >
      <g id="produkt" transform="translate(-0.041 -0.983)">
        <path
          className="white"
          id="Path_188"
          data-name="Path 188"
          d="M12,24.734h0L.041,20.321V5.994L12,10.408V24.734"
          fill="#fff"
        />
        <path
          className="white"
          id="Path_189"
          data-name="Path 189"
          d="M25.041,20.321,13.084,24.734V10.408L25.041,5.994Z"
          fill="#fff"
        />
        <path
          className="white"
          id="Path_190"
          data-name="Path 190"
          d="M1.064,5.218,12.542.983,24.017,5.218,12.542,9.453Z"
          fill="#fff"
        />
        <path
          className="red"
          id="Path_191"
          data-name="Path 191"
          d="M18.621,17.05l.006-.9,5.462-2.01-.006.9Z"
          fill="#ff3b3b"
        />
        <path
          className="red"
          id="Path_192"
          data-name="Path 192"
          d="M18.621,19.228l.006-.9,5.462-2.01-.006.9Z"
          fill="#ff3b3b"
        />
        <path
          className="red"
          id="Path_193"
          data-name="Path 193"
          d="M17.5,21.889l.009-.9,6.576-2.423-.006.9Z"
          fill="#ff3b3b"
        />
        <path
          className="red"
          id="Path_194"
          data-name="Path 194"
          d="M8.32,9.049v5.778L3.559,13.071V7.293Z"
          fill="#ff3b3b"
        />
        <path
          className="red"
          id="Path_195"
          data-name="Path 195"
          d="M4.579,6.511,8.861,4.93l4.282,1.581L8.861,8.091Z"
          fill="#ff3b3b"
        />
      </g>
    </svg>
  ),
  realizations: (
    <svg
      className="realizations"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24.978"
      viewBox="0 0 25 24.978"
    >
      <g
        id="realization_białe"
        data-name="realization"
        transform="translate(-0.153 0.125)"
      >
        <path
          className="white"
          id="Path_311"
          data-name="Path 311"
          d="M25.153,9.961H22.965a10.487,10.487,0,0,0-1.316-3.18l1.563-1.563-3.424-3.4L18.225,3.386a10.487,10.487,0,0,0-3.173-1.323V-.125H10.239V2.064A10.486,10.486,0,0,0,7.06,3.38L5.5,1.817,2.1,5.224,3.664,6.788A10.568,10.568,0,0,0,2.342,9.961H.153v4.812H2.342a10.568,10.568,0,0,0,1.316,3.18L2.095,19.516l3.408,3.4,1.563-1.563a10.586,10.586,0,0,0,3.183,1.316v2.189h4.8V22.664a10.568,10.568,0,0,0,3.18-1.31l1.563,1.563,3.4-3.408-1.563-1.563a10.558,10.558,0,0,0,1.319-3.183h2.189ZM12.647,16.7a4.33,4.33,0,1,1,4.327-4.329A4.33,4.33,0,0,1,12.647,16.7Z"
          fill="#fff"
        />
        <path
          id="Path_312"
          data-name="Path 312"
          d="M12.647,7a5.365,5.365,0,1,0,5.368,5.366A5.365,5.365,0,0,0,12.647,7Zm0,9.692a4.327,4.327,0,1,1,3.064-1.265A4.327,4.327,0,0,1,12.647,16.693Z"
          fill="#ebebeb"
        />
      </g>
    </svg>
  )
};
