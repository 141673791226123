import React, { Component } from "react";
import Layout from "../components/layout";
import { Breadcrumbs, Post, Button } from "../components/styleguide";
import { icons } from "../../utils/blog-icons.js";
import { Link } from "gatsby";
import MarkdownRenderer from "react-markdown-renderer";

const limit = 9;

class Blog extends Component {
  state = { limit };
  render() {
    const { data, common, breadcrumbs, categories, posts } =
      this.props.pageContext;

    return (
      <Layout
        data={common}
        title={data.seoTitle}
        description={data.seoDescription}
        hrefLang={data.hrefLang}>
        <div className="main-container">
          <Breadcrumbs data={breadcrumbs} lang={common.lang} />
          <header className="section-header">
            <MarkdownRenderer markdown={data.title} />
          </header>
          <div className="blog-container">
            <div className="nav-container">
              <input type="checkbox" id="toggle"></input>
              <label className="button-mobile font-ms" htmlFor="toggle">
                <span>Filtry</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.5"
                  height="17"
                  viewBox="0 0 20.5 17">
                  <g id="Group_635" transform="translate(-208.5 -1117)">
                    <line
                      id="Line_14"
                      x2="20"
                      transform="translate(208.5 1119.5)"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="2"></line>
                    <line
                      id="Line_15"
                      x2="20"
                      transform="translate(208.5 1125.5)"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="2"></line>
                    <line
                      id="Line_16"
                      x2="20"
                      transform="translate(208.5 1131.5)"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="2"></line>
                    <circle
                      id="Ellipse_3"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(224 1117)"
                      fill="#fff"></circle>
                    <circle
                      id="Ellipse_4"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(211 1123)"
                      fill="#fff"></circle>
                    <circle
                      id="Ellipse_5"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(216 1129)"
                      fill="#fff"></circle>
                  </g>
                </svg>
              </label>
              <div className="blog-nav">
                {categories.map(({ name, icon, active, link, id }) => (
                  <Link
                    key={id}
                    to={link}
                    className={`blog-nav--item font-ms ${
                      active ? "active" : ""
                    }`}>
                    {icons[icon]} <span>{name}</span>
                  </Link>
                ))}
              </div>
            </div>

            <div className="blog-posts grid x3">
              {posts &&
                posts.length > 0 &&
                posts
                  .slice(0, this.state.limit)
                  .map((post) => (
                    <Post
                      blogPath={common.blogLink}
                      key={post.id}
                      data={post}
                      className="fade-in"
                      primary
                    />
                  ))}
            </div>
            {this.state.limit < posts.length && (
              <div className="show-more-button-container">
                <Button
                  type={"button"}
                  fill
                  content={common.translations[0].show_more}
                  size="lg"
                  onClick={() => this.setState({ limit: this.state.limit + 9 })}
                />
              </div>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default Blog;
